/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react';
import styled from "styled-components";
import Plaatjie from '@ubo/plaatjie'

const StyledHero = styled.div`
  position: relative;
`;

const Image = styled(Plaatjie)`
  background-color: #e1e1e1;
  width: 100%;
  
  @media (min-width: 992px) { 
    height: ${props => props.small ? 300 : 400}px;
  }

  @media (max-width: 991px) {
    height: ${props => props.small ? 250 : 300}px;
  }

  @media (max-width: 767px) {
    height: 200px;
  }

  @media (max-width: 575px) {
    height: 150px;
  }
`

const HeroAlt = ({ className, src, children, small, big }) => {
  return (
    <StyledHero className={className}>
      <Image image={src} small={small} big={big} />

      {children && (
        <div className="py-4 color-background-main text-white w-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="mb-0">{children}</h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledHero>
  )
}

export default HeroAlt
